import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHAT_ENDPOINT } from "../../apis";
import "../../assets/css/chat.css";
import ReceivedMessage from "../../components/Chat/ReceivedMessage";
import SentMessage from "../../components/Chat/SentMessage";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import config from "../../config";
import { GetChatsAction } from "../../redux/actions/chat";
import Pusher from "pusher-js";
import qs from "qs";
import { NoMessage } from "../../constant";

const Chat = () => {
    const authToken = useSelector(state => state.auth.token);
    const authId = useSelector(state => state.auth.user.id);

    const chat = useSelector(state => state.chat);
    const dispatch = useDispatch();

    const [talkingToChatId, setTalkingToChatId] = useState(null);
    const [talkingToUser, setTalkingToUser] = useState({});
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState("");

    useEffect(() => {
        let hashSplitted = window.location.hash.split("?");
        if (hashSplitted.length > 1) {
            let params = qs.parse(hashSplitted[1]);
            if (params["user_id"]) {
                createChat(params["user_id"]);
            }
        }
        dispatch(GetChatsAction());

        if (hashSplitted.length > 1) {
            let params = qs.parse(hashSplitted[1]);
            if (params["chat_id"]) {
                document
                    .getElementsByClassName("all-chats")[0]
                    .childNodes.forEach(childElements => {
                        if (
                            childElements.dataset.chatId === params["chat_id"]
                        ) {
                            childElements.click();
                        }
                    });
            }
        }
    }, []);

    useEffect(() => {
        let pusher = null;
        let chatChannel = null;
        if (talkingToChatId && authId) {
            Pusher.logToConsole = false;
            pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
                cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER
            });

            chatChannel = pusher.subscribe(`chat.${talkingToChatId}`);
            chatChannel.bind("pusher:subscription_succeeded", () => {
                chatChannel.bind("App\\Events\\NewMessage", e => {
                    if (e.message.from_id != authId) {
                        appendMessage(e.message);
                    }
                });
            });
        }

        return () => {
            if (chatChannel && pusher) {
                chatChannel.unbind_all();
                pusher.unsubscribe(`chat.${talkingToChatId}`);
            }
        };
    }, [talkingToChatId, authId]);

    const appendMessage = newMessage => {
        setMessages(actualState => {
            return [newMessage, ...actualState];
        });
    };

    const onChatClick = data => {
        setTalkingToChatId(data.id);
        setTalkingToUser(data.talking_to);
        getMessages(data.id);
    };

    const getMessages = async (chatId = null) => {
        const headers = config.bearerHeaders(authToken);
        const url = CHAT_ENDPOINT + "/" + (chatId ? chatId : talkingToChatId);
        const res = await config.getApi(url, headers);
        if (res) {
            setMessages(res);
        }
    };

    const createChat = async userId => {
        const headers = config.bearerHeaders(authToken);
        const data = JSON.stringify({
            user_id: userId,
            message
        });
        const res = await config.postApi(CHAT_ENDPOINT, headers, data, false);
        dispatch(GetChatsAction());
        setTimeout(() => {
            console.log("clicking");
            document.getElementById(`chat_${userId}`).click();
        }, 2000);
    };

    const sendMessage = async () => {
        const headers = config.bearerHeaders(authToken);
        const data = JSON.stringify({
            chat_id: talkingToChatId,
            message
        });
        const res = await config.postApi(CHAT_ENDPOINT, headers, data, false);
        if (res) {
            let newMessages = [res, ...messages];
            setMessages(newMessages);
            setMessage("");
        }
    };

    return (
        <>
            {/* Header */}
            <Header />
            {/* Header */}

            {/* Chat Sec Start Here */}
            <section className="chat_sec">
                <div className="container">
                    {chat.length == 0 ? (
                        <div className="not-found text-center">
                            <figure>
                                <img
                                    src={NoMessage}
                                    alt="no-message-found"
                                    className="img-fluid"
                                />
                            </figure>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-lg-4 p-0">
                                <div className="chat-list">
                                    {/* <div className="title">
									<h4>Chats</h4>
								</div> */}
                                    <ul className="all-chats">
                                        {chat?.map((data, index) => {
                                            return (
                                                <li
                                                    data-chat-id={data.id}
                                                    id={`chat_${data.talking_to.id}`}
                                                    key={`chat_${data.id}`}
                                                    onClick={() =>
                                                        onChatClick(data)
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            data?.id ===
                                                            talkingToChatId
                                                                ? "chat actve-chat"
                                                                : "chat"
                                                        }
                                                    >
                                                        <figure>
                                                            <img
                                                                style={{
                                                                    height: 45,
                                                                    width: 45,
                                                                    borderRadius:
                                                                        "100%"
                                                                }}
                                                                src={config.returnAvatar(
                                                                    data.talking_to
                                                                )}
                                                                alt=""
                                                                className="img-fluid"
                                                            />
                                                        </figure>
                                                        <div className="name">
                                                            <h4 className="user_name">
                                                                {
                                                                    data
                                                                        ?.talking_to
                                                                        .full_name
                                                                }
                                                            </h4>
                                                            <h5>
                                                                {/* {data?.talking_to}{" "} */}
                                                                <span>
                                                                    {
                                                                        data?.last_message
                                                                    }
                                                                </span>
                                                            </h5>
                                                            {/* {data?.is_active ? (
                                                            <h5 className="online">
                                                                <span className="dot"></span>{" "}
                                                                Online
                                                            </h5>
                                                        ) : (
                                                            <h5 className="offline">
                                                                <span className="dot"></span>{" "}
                                                                offline
                                                            </h5>
                                                        )} */}
                                                        </div>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-8 p-0">
                                {talkingToChatId && talkingToUser ? (
                                    <div className="the-chats">
                                        <div className="chat-user">
                                            <figure>
                                                <img
                                                    style={{
                                                        height: 45,
                                                        width: 45,
                                                        borderRadius: "100%"
                                                    }}
                                                    src={config.returnAvatar(
                                                        talkingToUser
                                                    )}
                                                    alt="123"
                                                    className="img-fluid"
                                                />
                                            </figure>
                                            <div className="name">
                                                <h4>
                                                    {talkingToUser.full_name}
                                                </h4>
                                                {/* <h5>
                                            <span className="dot"></span> Online
                                        </h5> */}
                                            </div>
                                        </div>
                                        <div className="my-chat">
                                            <div className="my-chat-inner">
                                                {messages.map(message => {
                                                    if (
                                                        message.from_id ==
                                                        authId
                                                    ) {
                                                        return (
                                                            <SentMessage
                                                                key={`message_${message.id}`}
                                                                message={
                                                                    message
                                                                }
                                                            />
                                                        );
                                                    } else {
                                                        return (
                                                            <ReceivedMessage
                                                                key={`message_${message.id}`}
                                                                message={
                                                                    message
                                                                }
                                                            />
                                                        );
                                                    }
                                                })}
                                            </div>
                                        </div>
                                        <div className="send-new-message">
                                            <div className="for-new-message">
                                                <input
                                                    type="text"
                                                    name=""
                                                    placeholder="Enter your message here"
                                                    id=""
                                                    value={message}
                                                    onChange={e =>
                                                        setMessage(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="for-send-msg-btn">
                                                <button
                                                    type="button"
                                                    onClick={sendMessage}
                                                >
                                                    Send
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    )}
                </div>
            </section>
            {/* Chat Sec End Here */}

            {/* Footer */}
            <Footer />
            {/* Footer */}
        </>
    );
};

export default Chat;
