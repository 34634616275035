import config from "../../config";
import { LOGIN_ENDPOINT, REGISTER_ENDPOINT } from "../../apis";
import {
    CLEAR_ADMINS,
    CLEAR_POSTS,
    CLEAR_RELATIONS,
    CLEAR_TIMELINE,
    LOGIN,
    LOGOUT,
    REGISTER
} from "../actions/type";

export const RegisterAction = (
    firstName,
    lastName,
    email,
    password,
    dob,
    familyCode,
    familyName,
    gender
) => {
    return async (dispatch, getState) => {
        let data = JSON.stringify({
            first_name: firstName,
            last_name: lastName,
            email,
            password,
            family_code: familyCode,
            family_name: familyName,
            dob,
            gender
        });
        const res = await config.postApi(
            REGISTER_ENDPOINT,
            config.defaultHeaders,
            data
        );
        if (res) {
            dispatch({ type: REGISTER, payload: res });
        }
    };
};

export const LoginAction = (email, password) => {
    return async (dispatch, getState) => {
        let data = JSON.stringify({ email, password });
        const res = await config.postApi(
            LOGIN_ENDPOINT,
            config.defaultHeaders,
            data
        );
        if (res) {
            dispatch({ type: LOGIN, payload: res });
        }
    };
};

export const LogoutAction = () => (dispatch, getState) => {
    dispatch({ type: CLEAR_ADMINS });
    dispatch({ type: CLEAR_POSTS });
    dispatch({ type: CLEAR_RELATIONS });
    dispatch({ type: CLEAR_TIMELINE });
    dispatch({ type: LOGOUT });
};
