import React from 'react'

const Newsletter = () => {
    return (
        <>
            <section className="newsletter_sec">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="heading-wrapper wow animate__animated animate__fadeInLeft">
                                <h3>Learn Tips and <span className="colored">Tricks</span> with our
                                    Monthly Newsletter</h3>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <form action="">
                                <div className="form-group wow animate__animated animate__fadeInRight">
                                    <input type="email" placeholder='Enter your email' className="form-control" />
                                    <button className='forBtn'>Stay Hip</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Newsletter
