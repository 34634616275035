import React, { useEffect } from 'react'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import InnerBanner from '../../components/InnerBanner/InnerBanner'

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* Inner Banner Start Here */}
            <InnerBanner PageName="Term & Policy" />
            {/* Inner Banner End Here */}

            {/* Policy Sec Start Here */}
            <section className='policy'>
                <div className="container">
                    <div className="heading-wrapper">
                        <h3>Term & Policy</h3>
                    </div>
                    <div className="content-wrapper">
                        <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel necessitatibus enim nostrum quisquam quo sint perferendis, nam labore nisi similique magni aut doloremque tempore suscipit sunt consequatur! Eos illo suscipit facilis sunt sit quia, blanditiis, nobis provident ea dolores atque reiciendis asperiores rem. Ipsa incidunt molestias id ut, officiis veritatis sapiente a. Praesentium ipsum, deserunt ipsam ducimus facilis ratione quas, inventore odit doloribus nam dolorum exercitationem consequatur, eveniet in amet voluptas repellendus ullam a aspernatur nesciunt laudantium vero sapiente! Maiores ipsa</p><br />
                        <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel necessitatibus enim nostrum quisquam quo sint perferendis, nam labore nisi similique magni aut doloremque tempore suscipit sunt consequatur! Eos illo suscipit facilis sunt sit quia, blanditiis, nobis provident ea dolores atque reiciendis asperiores rem. Ipsa incidunt molestias id ut, officiis veritatis sapiente a. Praesentium ipsum, deserunt ipsam ducimus facilis ratione quas, inventore odit doloribus nam dolorum exercitationem consequatur, eveniet in amet voluptas repellendus ullam a aspernatur nesciunt laudantium vero sapiente! Maiores ipsa</p><br />
                        <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel necessitatibus enim nostrum quisquam quo sint perferendis, nam labore nisi similique magni aut doloremque tempore suscipit sunt consequatur! Eos illo suscipit facilis sunt sit quia, blanditiis, nobis provident ea dolores atque reiciendis asperiores rem. Ipsa incidunt molestias id ut, officiis veritatis sapiente a. Praesentium ipsum, deserunt ipsam ducimus facilis ratione quas, inventore odit doloribus nam dolorum exercitationem consequatur, eveniet in amet voluptas repellendus ullam a aspernatur nesciunt laudantium vero sapiente! Maiores ipsa</p><br />
                        <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel necessitatibus enim nostrum quisquam quo sint perferendis, nam labore nisi similique magni aut doloremque tempore suscipit sunt consequatur! Eos illo suscipit facilis sunt sit quia, blanditiis, nobis provident ea dolores atque reiciendis asperiores rem. Ipsa incidunt molestias id ut, officiis veritatis sapiente a. Praesentium ipsum, deserunt ipsam ducimus facilis ratione quas, inventore odit doloribus nam dolorum exercitationem consequatur, eveniet in amet voluptas repellendus ullam a aspernatur nesciunt laudantium vero sapiente! Maiores ipsa</p><br />
                        <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel necessitatibus enim nostrum quisquam quo sint perferendis, nam labore nisi similique magni aut doloremque tempore suscipit sunt consequatur! Eos illo suscipit facilis sunt sit quia, blanditiis, nobis provident ea dolores atque reiciendis asperiores rem. Ipsa incidunt molestias id ut, officiis veritatis sapiente a. Praesentium ipsum, deserunt ipsam ducimus facilis ratione quas, inventore odit doloribus nam dolorum exercitationem consequatur, eveniet in amet voluptas repellendus ullam a aspernatur nesciunt laudantium vero sapiente! Maiores ipsa</p><br />
                    </div>
                </div>
            </section>
            {/* Policy Sec End Here */}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    )
}

export default PrivacyPolicy
