import React from "react";
import { Link } from "react-router-dom";
import { ApplestoreBtn, Logo, PlaystoreBtn } from "../../constant";

const Footer = () => {
    return (
        <>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="footer-content">
                                <div className="footer-logo">
                                    <Link to="">
                                        <figure>
                                            <img
                                                src={Logo}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </figure>
                                    </Link>
                                </div>
                                <p>
                                    In 2020's, young or old, we all have our
                                    cell phone on us. We capture precious
                                    moments in film and photo that remain
                                    stagnant in our storage for years without
                                    being properly curated with Ancestream App
                                    all your best photos and videos can be
                                    immediately preserved not for month or years
                                    in some cloud but with triple protection for
                                    life, and even longer.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="footer-content">
                                <div className="title">
                                    <h4>Useful Links</h4>
                                </div>
                                <ul className="menus">
                                    <li>
                                        <Link to="/privacy-policy">
                                            Term & Policy
                                        </Link>
                                    </li>
                                    <li>
                                        <a href="https://trader.tradovate.com/help">
                                            Help
                                        </a>
                                    </li>
                                    <li>
                                        <Link to="/contact-us">Contact</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="footer-content">
                                <div className="title">
                                    <h4>Social Links</h4>
                                </div>
                                <ul className="social-media">
                                    <li>
                                        <a href="#" target={"_blank"}>
                                            <i
                                                className="fa fa-facebook"
                                                aria-hidden="true"
                                            ></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target={"_blank"}>
                                            <i
                                                className="fa fa-youtube-play"
                                                aria-hidden="true"
                                            ></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target={"_blank"}>
                                            <i
                                                className="fa fa-twitter"
                                                aria-hidden="true"
                                            ></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target={"_blank"}>
                                            <i
                                                className="fa fa-instagram"
                                                aria-hidden="true"
                                            ></i>
                                        </a>
                                    </li>
                                </ul>
                                <div className="button-group">
                                    <a href="#" taget="_blank">
                                        <img
                                            src={PlaystoreBtn}
                                            alt="app-btn"
                                            className="img-fluid"
                                        />
                                    </a>
                                    <a href="#" target={"_blank"}>
                                        <img
                                            src={ApplestoreBtn}
                                            alt="app-btn"
                                            className="img-fluid"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="copyrights">
                <p>
                    @copyright 2019 - {new Date().getFullYear()} by Ancestream
                </p>
            </div>
        </>
    );
};

export default Footer;
