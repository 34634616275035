import React from 'react'
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import InnerBanner from '../../components/InnerBanner/InnerBanner'
import { AboutPic, LongTermPic, PercentagePic, Planning } from '../../constant'

const How_We_Do = () => {
    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* Inner Banner Start Here */}
            <InnerBanner PageName="How We Do It" />
            {/* Inner Banner End Here */}

            {/* Long Term Sec Start Here */}
            <section className="universal_sec long_term">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="content-wrapper">
                                <h2 className="title">The Leader in <span className="colored">Generational</span> Storage</h2>
                                <p>
                                    Ancestream is leading the way to truly long term digital storage. By combining the centuries old financial strategy for Cemetery preservation with the latest in BlockChain Technology, we have innovated a way for all of us to give the greatest gift we could ever give. Your Digital Family Heirloom.<br /><br />
                                    We have all seen centuries old cemeteries still in incredible manicured condition, the reason why is a law that requires cemeteries to put the proceeds of the funds gained from the sale of a plot, into an interest bearing CD or similar investment vehicle. They are prohibited by law from using the capital for continued maintenance. At Ancestream we have enlisted the help of economists and financial planners and have created what we call The 75% Rule
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="img-box">
                                <figure><img src={LongTermPic} alt="" className="img-fluid" /></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Long Term Sec End Here */}

            {/* Rule 75% Sec Start Here */}
            <section className="universal_sec how_do">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="img-box">
                                <figure><img src={PercentagePic} alt="" className="img-fluid" /></figure>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="content-wrapper">
                                <h2 className="title">The <span className="colored">75%</span> Rule </h2>
                                <p>
                                    75% of your entire payment goes directly into an interest bearing investment. Then to outpace inflation, 75% of the interest we gain, <Link to="#">currently 9.68% per annum</Link>, is used to preserve your heirloom, ensuring that Marketing, offices, and the expansion of our underground bunker are all covered by the 25% of your initial investment, and that the remaining 25% of the annual interest compounds, to ensure the fund always remains in tact, outpacing even the highest inflation.<br /><br />
                                    From a technical viewpoint, we use Blockchain as a chain of custody that ensures every single item you wished to have saved in your stream remains completely in tact, immovable and most important of all, Undeleteable!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Rule 75% Sec End Here */}

            {/* Planning Sec Start Here */}
            <section className="universal_sec planning_sec">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="content-wrapper">
                                <h2 className="title">Planning for the <span className="colored">Unimaginable</span></h2>
                                <p>
                                    At Ancestream, we take minor threats very seriously. We have to, to ensure that every imaginable threat to your digital heirloom has been considered and addressed. That includes 4 redundant backups of your digital memories, the last being an off-line storage underground, within a Faraday cage, at 9,000 feet above sea level on the equator. Each of those has it’s own carefully considered reason.<br /><br />While that might sound like a bit of Tin hat conspiracy level thinking, we are proud to say it is. Planning for unimaginable possibilities, like a 1859 Carrington Level Event, or an EMP detonation designed to wipe out our grid, and potentially it’s data along with it.<br /><br />
                                    Truly we are crazy, crazy about ensuring come what may, that your digital heirloom remains completely in tact for generations to come.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="img-box">
                                <figure><img src={Planning} alt="" className="img-fluid" /></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Planning Term Sec End Here */}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    )
}

export default How_We_Do
