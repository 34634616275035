import { ADD_POST, GET_POSTS, GET_MORE_POSTS } from "./type";
import config from "../../config";
import { POST_ENDPOINT } from "../../apis";

export const GetPostsAction = () => async (dispatch, getState) => {
    const token = getState().auth.token;
    const headers = config.bearerHeaders(token);
    const res = await config.getApi(POST_ENDPOINT, headers);
    if (res) {
        dispatch({ type: GET_POSTS, payload: res });
    }
};

export const GetMorePostsAction = () => async (dispatch, getState) => {
    const token = getState().auth.token;
    const headers = config.bearerHeaders(token);
    const url = getState().posts.next_page_url;
    const res = await config.getApi(url, headers);
    if (res) {
        dispatch({ type: GET_MORE_POSTS, payload: res });
    }
};

export const AddPostAction = (
    caption = null,
    location = null,
    media = []
) => async (dispatch, getState) => {
    const token = getState().auth.token;
    let headers = config.bearerMultipartHeaders(token);
    let data = new FormData();
    data.append("caption", caption);
    data.append("location", location);
    media.forEach(singleMedia => {
        if (singleMedia.name) {
            data.append("media[]", singleMedia);
        }
    });
    const res = await config.postApi(POST_ENDPOINT, headers, data);
    if (res) {
        dispatch({ type: ADD_POST, payload: res });
    }
};
