import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const AddQuoteModal = ({ toggle, isOpen }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Add Quote</ModalHeader>
            <ModalBody>
                <input
                    type="text"
                    className="form-control form-control-sm mb-3 br-12"
                    placeholder="Reference"
                />
                <textarea
                    rows={5}
                    className="form-control form-control-sm br-12"
                    placeholder="Quote"
                />
            </ModalBody>
            <ModalFooter>
                <Button className="btn-sm" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default AddQuoteModal;
