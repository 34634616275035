import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import { CATEGORY_ENDPOINT } from "../../apis";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import config from "../../config";

const TimelineDetail = () => {
    const authToken = useSelector(state => state.auth.token);
    const { id } = useParams();

    const [loading, setLoading] = useState(true);

    const [category, setCategory] = useState({});

    useEffect(() => {
        getData();
        window.scrollTo(0, 0);
    }, []);

    const getData = async () => {
        try {
            const headers = config.bearerHeaders(authToken);
            const res = await config.getApi(
                `${CATEGORY_ENDPOINT}/${id}?include_timeline=1`,
                headers
            );
            if (res) {
                setCategory(res.category);
                setLoading(false);
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* InnerBanner Start Here */}
            <InnerBanner PageName={category?.name} />
            {/* InnerBanner End Here */}

            {/* Timeline Detail Page Start Here */}
            <section className="timeline-detail">
                <div className="container">
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center">
                            <Spinner style={{ color: config.primaryColor }} />
                        </div>
                    ) : (
                        <div className="row">
                            {category.family_timeline.map(singleTimeline => {
                                if (
                                    singleTimeline.media[0].media_type.split(
                                        "/"
                                    )[0] === "image"
                                ) {
                                    return (
                                        <div
                                            className="col-lg-4"
                                            key={`single_timeline_${singleTimeline.id}`}
                                        >
                                            <div className="timeline-box">
                                                <figure>
                                                    <img
                                                        src={`${process.env.REACT_APP_TIMELINE_URI}${singleTimeline.media[0].media_file}`}
                                                        alt=""
                                                        className="img-fluid"
                                                    />
                                                </figure>
                                                <div className="date">
                                                    <h4>
                                                        {moment(
                                                            singleTimeline.created_at
                                                        ).format("DD/MMM/YYYY")}
                                                    </h4>
                                                    <h4>58 Likes</h4>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                } else if (
                                    singleTimeline.media[0].media_type.split(
                                        "/"
                                    )[0] === "video"
                                ) {
                                    return (
                                        <div
                                            className="col-lg-4"
                                            key={`single_timeline_${singleTimeline.id}`}
                                        >
                                            <div className="timeline-box">
                                                <figure>
                                                    <video
                                                        controls
                                                        style={{ height: 250 }}
                                                        src={`${process.env.REACT_APP_TIMELINE_URI}${singleTimeline.media[0].media_file}`}
                                                    />
                                                </figure>
                                                <div className="date">
                                                    <h4>
                                                        {moment(
                                                            singleTimeline.created_at
                                                        ).format("DD/MMM/YYYY")}
                                                    </h4>
                                                    {/* <h4>58 Likes</h4> */}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    )}
                </div>
            </section>
            {/* Timeline Detail Page End Here */}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

export default TimelineDetail;
