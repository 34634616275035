import React from "react";
import { Link } from "react-router-dom";
import config from "../../config";
import { connect } from "react-redux";

const Sidebar = ({ user }) => {
    return (
        <>
            <div className="profile_sidebar">
                <div className="profile-header">
                    <div className="profile-img">
                        <figure>
                            <img
                                src={config.returnAvatar(user)}
                                alt=""
                                className="img-fluid"
                            />
                        </figure>
                    </div>
                    <div className="name">
                        <h4>{user.full_name}</h4>
                        <p>{user.profession}</p>
                        <Link to="/edit-profile">Edit Profile</Link>
                    </div>
                </div>
                <ul className="menus">
                    <li className="menu-items">
                        <Link to="/profile" className="menu-links">
                            Profile
                        </Link>
                    </li>
                    <li className="menu-items">
                        <Link
                            to="/family-stream-contribution"
                            className="menu-links"
                        >
                            Family stream contribution
                        </Link>
                    </li>
                    <li className="menu-items">
                        <Link to="/favourite-quotes" className="menu-links">
                            Favourite Quotes
                        </Link>
                    </li>
                    <li className="menu-items">
                        <Link to="/hobbies" className="menu-links">
                            Interests/hobbies
                        </Link>
                    </li>
                    <li className="menu-items">
                        <div id="accordion">
                            <div className="card">
                                <div className="card-header" id="headingTwo">
                                    <h5 className="mb-0">
                                        <button
                                            className="btn btn-link collapsed"
                                            data-toggle="collapse"
                                            data-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                        >
                                            <h5>Pets</h5>
                                            <i
                                                className="fa fa-angle-down"
                                                aria-hidden="true"
                                            ></i>
                                        </button>
                                    </h5>
                                </div>
                                <div
                                    id="collapseTwo"
                                    className="collapse"
                                    aria-labelledby="headingTwo"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">Dog</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="menu-items">
                        <Link to="/pets" className="menu-links">
                            Pets
                        </Link>
                    </li>
                    <li className="menu-items">
                        <Link to="/sports" className="menu-links">
                            Sports & Sports Team
                        </Link>
                    </li>
                    <li className="menu-items">
                        <Link to="/work" className="menu-links">
                            Work
                        </Link>
                    </li>
                    <li className="menu-items">
                        <Link to="/place-lived" className="menu-links">
                            Places Lived
                        </Link>
                    </li>
                    <li className="menu-items">
                        <Link to="/medical" className="menu-links">
                            Medical: Blood Type/ Allergy
                        </Link>
                    </li>
                    <li className="menu-items">
                        <Link to="/secrets" className="menu-links">
                            Secret to life
                        </Link>
                    </li>
                    <li className="menu-items">
                        <Link to="/cars" className="menu-links">
                            Cars
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    user: state.auth.user
});

export default connect(mapStateToProps)(Sidebar);
