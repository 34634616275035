import React from 'react'
import { Link } from 'react-router-dom'
import { Icon1, Icon2, Icon3, TreePic } from '../../constant'

const FamilyTree = () => {
    return (
        <>
            <section className="family_tree">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="content-wrapper">
                                <h2 className='wow animate__animated animate__fadeInLeft'>Don’t Let <span className="colored">Centuries</span> Keep You Apart.</h2>
                                <div className="boxes">
                                    <div className="icon-box wow animate__animated animate__fadeInDown animate__delay-1s">
                                        <figure><img src={Icon1} alt="icon" className="img-fluid" /></figure>
                                        <h4>Family Stream</h4>
                                    </div>
                                    <div className="icon-box wow animate__animated animate__fadeInDown animate__delay-2s">
                                        <figure><img src={Icon2} alt="icon" className="img-fluid" /></figure>
                                        <h4>Timeline</h4>
                                    </div>
                                    <div className="icon-box wow animate__animated animate__fadeInDown animate__delay-3s">
                                        <figure><img src={Icon3} alt="icon" className="img-fluid" /></figure>
                                        <h4>Time Capsule</h4>
                                    </div>
                                </div>
                                <p className='wow animate__animated animate__fadeIn animate__delay-4s'>
                                    Easily create your Profile within your family Stream. Record Video memoirs and choose between hundreds of our most popular questions or simply make up your own!<br /><br />
                                    With Ancestream, you are in control of your Time Capsule, You decide which photos, videos, interviews, documents and family history get preserved. It’s all up to you.
                                    Give the Gift that Thousands are talking about - a Digital Family Heirloom that will outlive us all.

                                </p>
                                <div className="button-group wow animate__animated animate__fadeInUp animate__delay-4s">
                                    <Link to="#" className='forBtn'>Learn More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="tree-img text-center wow animate__animated animate__fadeInRight">
                                <figure><img src={TreePic} alt="" className="img-fluid" /></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FamilyTree
