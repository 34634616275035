import React from 'react'
import { Link } from 'react-router-dom'
import { Storage } from '../../constant'

const DigitalStorage = () => {
    return (
        <>
            <section className="digital_storage">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="img-box  wow animate__animated animate__fadeInLeft">
                                <figure><img src={Storage} alt="" className="img-fluid" /></figure>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="content-wrapper">
                                <h2 className='mb-3  wow animate__animated animate__fadeInDown animate__delay-1s'>Global Leaders in <span className="colored">Generational Storage</span>.</h2>
                                <p className='wow animate__animated animate__fadeInDown animate__delay-2s'>
                                    At Ancestream we are honored to have been recognized as the Global Leader in <a href="#">Generational Storage</a>.  We have achieved that lofty goal by following one simple philosophy - Our Clients count on us as a bridge to the Future.<br /><br />
                                    By Merging the latest in <Link to="#">BlockChain Technology</Link> with the Centuries old concept of <Link to="#">“Perpetual Funding”</Link> We have come up with a solution that will allow your Great Great Grand kids to know you, to know your story, to understand where they came from.<br /><br />
                                    At Ancestream, we have what we call <Link to="#">Tin Hat Tuesday</Link> , Our regular Data Survival meetings encourage the crazy, the “out there” ideas to take center stage. We have taken Generational Storage to the extreme to make sure Your Digital Heirloom always survives.  From Tsunamis, to Hurricanes, <Link to="#">Carrington Level Events</Link> to Nuclear Blast, if there is the slightest chance of occurrence, we’ve thought of <Link to="#">the solution</Link>.<br /><br />
                                    {/* We started Ancestream because we believed that in a world seemingly getting dummer, that others, like us want to offer our future generations  as much help along the path as we might ourselves have.  If that sounds like you, welcome! Start free for 90 days, If you still aren’t sure, we’ll give you another 90! No Questiosn asked, no pushy salespeople and best of all-<br /> */}
                                    We started Ancestream because we wanted to give our knowledge, our perception, our own story to generations that will not know what the world was like before Cell Phones, We wanted our Great Great Grandchildren to know far more about us than we knew about ours. So much generational history has been lost, and we wanted to use technology to preserve  the memories of a lifetime.<br /><br />
                                    Think of Ancestream as your Digital Family Heirloom, fill it with family recipes, (and videos of you cooking that recipe) Fill it with hours of you recounting everything you can remember about your life. Imagine if you found hours of video of your Great Grand Parents talking about their life, Give that gift to your future, with your families Digital Heirloom.<br /><br />
                                    If that sounds like you, welcome home! Start free for 90 days, If you still aren’t sure, we’ll give you another 90! No Questions asked, no pushy salespeople and best of all-<br />
                                    <b>no credit card required. No strings attached. </b>
                                </p>
                                <div className="button-group wow animate__animated animate__fadeInUp animate__delay-3s">
                                    <Link to="" className='forBtn'>Join For Free</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DigitalStorage
