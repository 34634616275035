import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import FamilyTree from "@balkangraph/familytree.js";
import config from "../../config";
import { CONNECTIONS_ENDPOINT, RELATIONS_ENDPOINT } from "../../apis";
import { connect } from "react-redux";
import AddMemberModal from "../../components/FamilyMember/AddMemberModal";
import AddConnectionModal from "../../components/FamilyMember/AddConnectionModal";
import { Link } from "react-router-dom";

// FamilyTree.templates.tommy.img_0 =
// '<image preserveAspectRatio="xMidYMid slice" xlink:href="{val}" x="20" y="-15" width="80" height="80"></image>';
// FamilyTree.templates.tommy.img_1 =
// '<image preserveAspectRatio="xMidYMid slice" xlink:href="{val}" x="150" y="-15" width="80" height="80"></image>';

const FamilyContribution = ({ auth }) => {
    const [familyMembers, setFamilyMembers] = useState([]);
    const [memberModal, setMemberModal] = useState(false);
    const [addConnectionModal, setAddConnectionModal] = useState(false);

    const [modalSpinner, setModalSpinner] = useState(false);

    useEffect(() => {
        getRelations().then(() => {});
    }, []);

    const getRelations = async () => {
        try {
            const res = await config.getApi(
                RELATIONS_ENDPOINT,
                config.bearerHeaders(auth.token)
            );
            setFamilyMembers(res);
            initTree(res);
        } catch (e) {
            console.log(e);
        }
    };

    const initTree = async relations => {
        let nodes = relations.map(member => {
            let singleMember = {
                id: member.id,
                name: member.full_name,
                first_name: member.first_name,
                last_name: member.last_name,
                // email: member.email,
                gender: member.gender ? member.gender.toLowerCase() : "",
                avatar: config.returnAvatar(member),
                dob: member.dob
            };
            if (member.father_id.length > 0) {
                singleMember["fid"] = member.father_id[0];
            }
            if (member.mother_id.length > 0) {
                singleMember["mid"] = member.mother_id[0];
            }
            if (member.partner_ids.length > 0) {
                singleMember["pids"] = member.partner_ids;
            }
            return singleMember;
        });
        let family = new FamilyTree(
            document.getElementById("familyTreeContainer"),
            {
                nodeTreeMenu: true,
                // nodeMouseClick: FamilyTree.action.none,
                editForm: {
                    titleBinding: "name",
                    photoBinding: "avatar",
                    generateElementsFromFields: false,
                    elements: [
                        [
                            {
                                label: "First Name",
                                binding: "first_name",
                                type: "textbox"
                            },
                            {
                                label: "Last Name",
                                binding: "last_name",
                                type: "textbox"
                            }
                        ],
                        {
                            label: "Gender",
                            type: "select",
                            options: [
                                { value: "male", text: "Male" },
                                { value: "female", text: "Female" }
                            ],
                            binding: "gender"
                        },
                        [
                            {
                                label: "Date of Birth",
                                type: "date",
                                binding: "dob"
                            },
                            {
                                label: "Date of Death",
                                type: "date",
                                binding: "dod"
                            }
                        ],
                        {
                            label: "Avatar",
                            type: "textbox",
                            binding: "avatar"
                        }
                    ],
                    addMore: false
                    // addMoreBtn: false
                },
                nodeBinding: {
                    field_0: "name",
                    field_1: "gender",
                    field_2: "dob",
                    field_3: "first_name",
                    field_4: "last_name",
                    img_0: "avatar"
                },
                template: "hugo",
                mode: "dark",
                nodes
            }
        );
        // family.generateId = async () => {
        //     try {
        //         const headers = config.bearerHeaders(auth.token);
        //         const res = await config.postApi(
        //             RELATIONS_ENDPOINT + "/generate-id",
        //             headers,
        //             JSON.stringify({ gender: "male" }),
        //             false
        //         );
        //         if (res) {
        //             setNewNode(res.id);
        //             return res.id.toString();
        //         }
        //     } catch (e) {
        //         console.log(e);
        //     }
        // };
        family.on("update", async (e, args) => {
            if (args.addNodesData.length > 0) {
                args.addNodesData.forEach(async singleNode => {
                    console.log(singleNode);
                    try {
                        const headers = config.bearerHeaders(auth.token);
                        const res = await config.postApi(
                            RELATIONS_ENDPOINT + "/generate-id",
                            headers,
                            JSON.stringify({
                                gender: singleNode.gender,
                                balkan_node_id: singleNode.id,
                                mid: singleNode.mid,
                                fid: singleNode.fid,
                                pids: singleNode.pids
                            }),
                            false
                        );
                        if (res) {
                            console.log(res);
                        }
                    } catch (e) {
                        console.log(e);
                    }
                });
            }

            if (args.updateNodesData.length > 0) {
                args.updateNodesData.forEach(async singleNode => {
                    await updateMember(
                        singleNode.id,
                        singleNode.first_name,
                        singleNode.last_name,
                        singleNode.avatar,
                        singleNode.gender,
                        singleNode.dob,
                        singleNode.dod,
                        singleNode.pids,
                        singleNode.mid,
                        singleNode.fid
                    );
                });
            }
            await getRelations();
        });
    };

    const updateMember = async (
        id,
        first_name = null,
        last_name = null,
        avatar = null,
        gender = null,
        dob = null,
        dod = null,
        pid = [],
        mid = null,
        fid = null
    ) => {
        console.log("updating member with id : " + id);
        try {
            const headers = config.bearerHeaders(auth.token);
            const data = JSON.stringify({
                first_name,
                last_name,
                avatar,
                gender,
                dob,
                dod,
                pids: pid,
                mid,
                fid
            });
            const res = await config.putApi(
                RELATIONS_ENDPOINT + "/" + id,
                headers,
                data,
                false
            );
            if (res) {
                console.log("updated!");
            }
        } catch (e) {
            console.log(e);
        }
    };

    const [newNode, setNewNode] = useState("");

    const addMember = async payload => {
        try {
            setModalSpinner(true);
            let data = new FormData();
            data.append("first_name", payload.firstName);
            data.append("last_name", payload.lastName);
            data.append("dob", payload.dob);
            data.append("gender", payload.gender);
            data.append("connection_name", payload.connectionName);
            data.append("relation_to_member_id", payload.relationToUserId);
            if (payload.dod) {
                data.append("dod", payload.dod);
            }
            if (payload.deceased === true) {
                data.append("deceased", 1);
            }
            if (payload.avatar && payload.avatar.name) {
                data.append("avatar", payload.avatar);
            }
            const headers = config.bearerMultipartHeaders(auth.token);
            const res = await config.postApi(RELATIONS_ENDPOINT, headers, data);
            if (res) {
                await getRelations();
                setMemberModal(false);
            }
            setModalSpinner(false);
        } catch (e) {
            setModalSpinner(false);
            console.log(e);
        }
    };

    const addConnection = async payload => {
        try {
            setModalSpinner(true);
            let data = JSON.stringify({
                connection_name: payload.connectionName,
                relation_of_id: payload.relationOfId,
                relation_to_id: payload.relationToId
            });
            const res = await config.postApi(
                CONNECTIONS_ENDPOINT,
                config.bearerHeaders(auth.token),
                data
            );

            if (res) {
                await getRelations();
                setAddConnectionModal(false);
            }
            setModalSpinner(false);
        } catch (e) {
            setModalSpinner(false);
            console.log(e);
        }
    };

    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* InnerBanner Sec Start Here */}
            <InnerBanner PageName="Family Stream" />
            {/* InnerBanner Sec End Here */}

            <section className="profile_sec">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-lg-3"> */}
                        {/* Sidebar Start Here */}
                        {/* <Sidebar /> */}
                        {/* Sidebar End Here */}
                        {/* </div> */}
                        <div className="col-lg-12">
                            <div className="top-buttons">
                                <div className="left-buttons">
                                    <Link className="back-to-home" to="/">
                                        <i
                                            className="fa fa-angle-left"
                                            aria-hidden="true"
                                        ></i>
                                        Back to home
                                    </Link>
                                    <button
                                        type="button"
                                        className="btn text-white"
                                        style={{
                                            backgroundColor: config.primaryColor
                                        }}
                                        onClick={() => setMemberModal(true)}
                                    >
                                        Add Member
                                    </button>
                                </div>
                                <button
                                    type="button"
                                    className="btn text-white ml-2"
                                    style={{
                                        backgroundColor: config.primaryColor
                                    }}
                                    onClick={() => setAddConnectionModal(true)}
                                >
                                    Add Connection
                                </button>
                            </div>
                            <div className="family_contribution_content">
                                <div
                                    id="familyTreeContainer"
                                    style={{ marginTop: 20 }}
                                ></div>
                            </div>
                            <AddMemberModal
                                isOpen={memberModal}
                                toggle={() => setMemberModal(!memberModal)}
                                onSubmit={addMember}
                                members={familyMembers}
                                spinner={modalSpinner}
                            />
                            <AddConnectionModal
                                isOpen={addConnectionModal}
                                toggle={() =>
                                    setAddConnectionModal(!addConnectionModal)
                                }
                                onSubmit={addConnection}
                                members={familyMembers}
                                spinner={modalSpinner}
                            />
                        </div>
                        {/* <div className="col-lg-3"> */}
                        {/* Right Sidebar Start Here */}
                        {/* <RecentBar /> */}
                        {/* Right Sidebar End Here */}
                        {/* </div> */}
                    </div>
                </div>
            </section>

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(FamilyContribution);
