export const LOGIN = "LOGIN";
export const REGISTER = "REGISTER";
export const LOGOUT = "LOGOUT";
export const ADD_POST = "ADD_POST";
export const GET_POSTS = "GET_POSTS";
export const GET_MORE_POSTS = "GET_MORE_POSTS";
export const GET_RELATIONS = "GET_RELATIONS";
export const GET_ADMINS = "GET_ADMINS";
export const ADD_ADMIN = "ADD_ADMIN";
export const GET_TIMELINE = "GET_TIMELINE";
export const LOAD_MORE_TIMELINE = "LOAD_MORE_TIMELINE";
export const ADD_TIMELINE = "ADD_TIMELINE";
export const GET_PENDING_TIMELINE = "GET_PENDING_TIMELINE";
export const GET_RECENT_TIMELINE = "GET_RECENT_TIMELINE";
export const GET_APPROVED_TIMELINE = "GET_APPROVED_TIMLINE";
export const GET_REJECTED_TIMELINE = "GET_REJECTED_TIMELINE";
export const LOAD_MORE_PENDING_TIMELINE = "LOAD_MORE_PENDING_TIMELINE";
export const LOAD_MORE_RECENT_TIMELINE = "LOAD_MORE_RECENT_TIMELINE";
export const LOAD_MORE_APPROVED_TIMELINE = "LOAD_MORE_APPROVED_TIMELINE";
export const LOAD_MORE_REJECTED_TIMELINE = "LOAD_MORE_REJECTED_TIMELINE";
export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS";
export const ADD_PAYMENT_METHODS = "ADD_PAYMENT_METHODS";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const GET_CHATS = "GET_CHATS";
export const GET_QUOTES = "GET_QUOTES";

export const GET_HOBBIES = "GET_HOBBIES";

export const CLEAR_ADMINS = "CLEAR_ADMINS";
export const CLEAR_POSTS = "CLEAR_POSTS";
export const CLEAR_RELATIONS = "CLEAR_RELATIONS";
export const CLEAR_TIMELINE = "CLEAR_TIMELINE";
export const CLEAR_PAYMENT_METHODS = "CLEAR_PAYMENT_METHODS";
export const CLEAR_CHATS = "CLEAR_CHATS";
export const CLEAR_QUOTES = "CLEAR_QUOTES";
