import React, { useEffect } from 'react'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import InnerBanner from '../../components/InnerBanner/InnerBanner'
import "../../assets/css/contact.css"
import { ContactImg } from '../../constant'

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            {/* Header Sec Start Here */}
            <Header />
            {/* Header Sec Start Here */}

            {/* Inner Banner Sec Start Here */}
            <InnerBanner PageName="Contact" />
            {/* Inner Banner Sec End Here */}

            {/* Contact Sec Start Here */}
            <section className="contact_sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="contact_img">
                                <figure><img src={ContactImg} alt="" className="img-fluid" /></figure>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="heading">
                                <h3>Get In Touch</h3>
                            </div>
                            <form action="">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" placeholder='First Name' className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" placeholder='Last Name' className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="email" placeholder='Email' className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="tel" placeholder='Phone' className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="text" placeholder='Subject' className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <textarea placeholder='Message' className='form-control'></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="button-group">
                                            <button className="btn">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            {/* Contact Sec End Here */}

            {/* Footer Sec Start Here */}
            <Footer />
            {/* Footer Sec End Here */}
        </>
    )
}

export default Contact
