import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { CARS_ENDPOINT, HOBBY_ENDPOINT, PETS_ENDPOINT } from "../../apis";
import config from "../../config";
import { connect } from "react-redux";

const AddCarsModal = ({ toggle, isOpen, getData, editData, authToken }) => {
    const [carMake, setCarMake] = useState("");
    const [carModel, setCarModel] = useState("");
    const [carYear, setCarYear] = useState();

    const handleCreate = async event => {
        event.preventDefault();
        if (editData) {
            try {
                // setSpinner(true);
                const headers = config.bearerHeaders(authToken);
                const data = JSON.stringify({
                    car_make: carMake,
                    car_model: carYear,
                    car_year: carYear
                });
                const res = await config.putApi(
                    `${CARS_ENDPOINT}/${editData.id}`,
                    headers,
                    data
                );
                if (res) {
                    setCarMake("");
                    setCarModel("");
                    setCarYear("");
                    getData();
                    toggle();
                }

                // setSpinner(false);
            } catch (e) {
                // setSpinner(false);
                console.log(e);
            }
        } else {
            try {
                // setSpinner(true);
                const headers = config.bearerHeaders(authToken);
                const data = JSON.stringify({
                    car_make: carMake,
                    car_model: carYear,
                    car_year: carYear
                });
                const res = await config.postApi(CARS_ENDPOINT, headers, data);
                if (res) {
                    setCarMake("");
                    setCarModel("");
                    setCarYear("");
                    getData();
                    toggle();
                }
                // setSpinner(false);
            } catch (e) {
                // setSpinner(false);
                console.log(e);
            }
        }
    };

    useEffect(() => {
        if (editData) {
            setCarMake(editData?.car_make);
            setCarModel(editData?.car_model);
            setCarYear(editData?.car_year);
        }
    }, [editData]);

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle} className="h2">
                {editData ? "Edit Car" : "Add Car"}
            </ModalHeader>
            <ModalBody>
                <p className="small mb-1">Car Make</p>
                <input
                    type="text"
                    className="form-control form-control-sm mb-3"
                    // placeholder="Pet Name Here..."
                    value={carMake}
                    onChange={e => setCarMake(e.target.value)}
                />
                <p className="small mb-1">Car Model</p>
                <input
                    type="text"
                    className="form-control form-control-sm mb-3"
                    // placeholder="Owner Name Here..."
                    value={carModel}
                    onChange={e => setCarModel(e.target.value)}
                />
                <p className="small mb-1">Car Year</p>
                <input
                    type="number"
                    className="form-control form-control-sm mb-3"
                    value={carYear}
                    onChange={e => setCarYear(e.target.value)}
                />
            </ModalBody>
            <ModalFooter>
                <Button className="btn-sm" onClick={toggle}>
                    Cancel
                </Button>
                <Button className="btn-success btn-sm" onClick={handleCreate}>
                    {editData ? "Update" : "Create"}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const mapStateToProps = state => ({
    authToken: state.auth.token
});

export default connect(mapStateToProps)(AddCarsModal);
