import { HOBBY_ENDPOINT } from "../../apis";
import config from "../../config";
import { GET_HOBBIES } from "./type";

export const GetHobbysAction = () => async (dispatch, getState) => {
    const headers = config.bearerHeaders(getState().auth.token);
    const res = await config.getApi(HOBBY_ENDPOINT, headers);
    if (res) {
        dispatch({ type: GET_HOBBIES, payload: res });
    }
};
