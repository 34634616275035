import React, { useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import RecentBar from "./RecentBar";
import Sidebar from "./Sidebar";
import Quote from "../../components/Quotes/Quote";
import AddQuoteModal from "../../components/Quotes/AddQuoteModal";
import { useSelector } from "react-redux";

const FavouriteQuotes = () => {
    const [modalIsOpen, setModalIsOpen] = useState(true);

    const toggleModal = () => {
        setModalIsOpen(!modalIsOpen);
    };

    const quotes = useSelector(state => state.quotes);

    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* InnerBanner Sec Start Here */}
            <InnerBanner PageName="Profile" />
            {/* InnerBanner Sec End Here */}

            <section className="profile_sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            {/* Sidebar Start Here */}
                            <Sidebar />
                            {/* Sidebar End Here */}
                        </div>
                        <div className="col-lg-6">
                            <div className="favourite_quote_content">
                                <div className="title">
                                    <h3>Favourite Quotes</h3>
                                </div>
                                <div className="row">
                                    {quotes.map(singleQuote => (
                                        <div
                                            className="col-12"
                                            key={`quote_${singleQuote.id}`}
                                        >
                                            <Quote
                                                content={singleQuote.quote}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            {/* Right Sidebar Start Here */}
                            <RecentBar />
                            {/* Right Sidebar End Here */}
                        </div>
                    </div>
                </div>
            </section>

            <AddQuoteModal isOpen={modalIsOpen} toggle={toggleModal} />

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

export default FavouriteQuotes;
