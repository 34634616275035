import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CATEGORY_ENDPOINT } from "../../apis";
import config from "../../config";
import { RecentPic1, RecentPic2 } from "../../constant";

const RecentBar = () => {
    const [categories, setCategories] = useState([]);

    const authToken = useSelector(state => state.auth.token);

    const loadCategories = async () => {
        const headers = config.bearerHeaders(authToken);
        const res = await config.getApi(CATEGORY_ENDPOINT, headers);
        if (res) {
            setCategories(res);
        }
    };

    useEffect(() => {
        loadCategories().then();
    }, []);

    const RecentContent = [
        {
            id: 1,
            Img: RecentPic1,
            Description: "Uncle paul just added a new video"
        },
        {
            id: 2,
            Img: RecentPic2,
            Description: "Mom just commented on uncle pauls video"
        },
        {
            id: 3,
            Img: RecentPic1,
            Description: "Papa just Liked on uncle pauls video"
        },
        {
            id: 4,
            Img: RecentPic1,
            Description: "Brother just shared a uncle pauls video"
        },
        {
            id: 5,
            Img: RecentPic1,
            Description: "Uncle paul just added a new video"
        },
        {
            id: 6,
            Img: RecentPic2,
            Description: "Mom just commented on uncle pauls video"
        }
    ];
    return (
        <>
            <div className="right_sidebar">
                <div className="recent-box">
                    <div className="title">
                        <h4>Recently added to the stream</h4>
                    </div>
                    <ul className="list">
                        {RecentContent?.map((data, index) => {
                            return (
                                <li className="menu-items" key={index}>
                                    <Link to="#">
                                        <figure>
                                            <img
                                                src={data?.Img}
                                                alt="recently"
                                                className="img-fluid"
                                            />
                                        </figure>
                                        <div className="description">
                                            <p>{data?.Description}</p>
                                        </div>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <Link to="/add-timeline" className="btn btn-success mb-2">
                    Add to Timeline
                </Link>
                <div className="your-timline-box">
                    <div className="title">
                        <h4>Your Timline</h4>
                    </div>
                    <ul className="timeline-years">
                        {categories.map(category => (
                            <li key={`category_${category.id}`}>
                                <Link to={`/timelines/${category.id}`}>
                                    <div className="box">
                                        <span className="year">
                                            {category.name}
                                        </span>{" "}
                                        <span className="count">
                                            {category.timeline_count}
                                        </span>
                                    </div>
                                </Link>
                            </li>
                        ))}
                        {/* <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2008</span>{" "}
                                    <span className="count">5</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2009</span>{" "}
                                    <span className="count">5</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2010</span>{" "}
                                    <span className="count">5</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2011</span>{" "}
                                    <span className="count">5</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2012</span>{" "}
                                    <span className="count">5</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2013</span>{" "}
                                    <span className="count">5</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2014</span>{" "}
                                    <span className="count">5</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2015</span>{" "}
                                    <span className="count">5</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2016</span>{" "}
                                    <span className="count">1</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2017</span>{" "}
                                    <span className="count">3</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2018</span>{" "}
                                    <span className="count">8</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2019</span>{" "}
                                    <span className="count">3</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2020</span>{" "}
                                    <span className="count">2</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2021</span>{" "}
                                    <span className="count">4</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2022</span>{" "}
                                    <span className="count">1</span>
                                </div>
                            </Link>
                        </li> */}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default RecentBar;
