import React from 'react'
import { Link } from 'react-router-dom'
import { Abstract, Dots } from '../../constant'

const Banner = () => {
    return (
        <>
            <section className="banner_sec">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="content-wrapper">
                                <h2 className='wow animate__animated animate__fadeInLeft'>Your <span className="colored">Digital Family</span> Heirloom</h2>
                                <p className='wow animate__animated animate__fadeInDown animate__delay-2s'>
                                    Ancestream is on a mission to ensure you are remembered forever.. As the recognized leader in Generational Storage, only Ancestream offers a <Link to="#">One Hundred Year Guarantee</Link>. Through our Innovation in merging <Link to="#">Blockchain storage</Link> with <Link to="#">Perpetual Funding</Link> , Ancestream will protect your families Digital Legacy for Generations to come.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="img-box wow animate__animated animate__fadeIn animate__delay-1s">
                                {/* <figure><img src={BannerImg} alt="banner_img" className="img-fluid" /></figure> */}
                                <iframe width="100%" height="300" src="https://www.youtube.com/embed/'+ id +'v4MpuNeJXmo?autoplay=1&loop=1&playlist=v4MpuNeJXmo" title="YouTube video player" frameBorder="0" allow="accelerometer; & clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                            <div className="dots-img">
                                <figure className='wow animate__animated animate__bounceIn animate__delay-3s'><img src={Dots} alt="" className="img-fluid" /></figure>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="gift">
                                <h3>The Greatest Gift You Will Ever Give.</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="elements element1">
                    <img src={Abstract} alt="" className="img-fluid" />
                </div>
                <div className="elements element2">
                    <img src={Abstract} alt="" className="img-fluid" />
                </div>
                <div className="elements element3">
                    <img src={Abstract} alt="" className="img-fluid" />
                </div>
                <div className="elements element4">
                    <img src={Abstract} alt="" className="img-fluid" />
                </div>
                <div className="elements element5">
                    <img src={Abstract} alt="" className="img-fluid" />
                </div>
                <div className="elements element6">
                    <img src={Abstract} alt="" className="img-fluid" />
                </div>
            </section>
        </>
    )
}

export default Banner
