import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import {
    StorageFullIcon,
    StorageRemainIcon,
    StorageUsedIcon
} from "../../constant";
import { AddAdminAction, GetAdminsAction } from "../../redux/actions/admins";
import { GetRelationsAction } from "../../redux/actions/relations";
import MyInput from "../../components/MyInput";
import { Col, FormGroup, Input, Label, Spinner } from "reactstrap";
import MySelect from "../../components/MySelect";
import MyCheckbox from "../../components/MyCheckbox";
import FormException from "../../exceptions/FormException";
import moment from "moment";
import config from "../../config";
import { REMOVE_ADMIN_ENDPOINT, SUBSCRIPTIONS_ENDPOINT } from "../../apis";
import { Link } from "react-router-dom";

const Owner = ({
    admins,
    addAdminAction,
    getAdminsAction,
    getRelationsAction,
    members,
    authToken
}) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [needsApproval, setNeedsApproval] = useState(true);
    const [memberId, setMemberId] = useState(null);

    const [currentPackage, setCurrentPackage] = useState({});

    const [addingAdmin, setAddingAdmin] = useState(false);

    const [errors, setErrors] = useState({});

    useEffect(() => {
        getRelationsAction().then(() => {
            getAdminsAction().then(() => {
                getCurrentPlan().then();
            });
        });
    }, []);

    const getCurrentPlan = async () => {
        try {
            const headers = config.bearerHeaders(authToken);
            const res = await config.getApi(SUBSCRIPTIONS_ENDPOINT, headers);
            if (res && res.length > 0) {
                console.log(res[0].package);
                setCurrentPackage(res[0].package);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const addAdmin = async () => {
        try {
            setAddingAdmin(true);
            await addAdminAction(
                memberId,
                firstName,
                lastName,
                email,
                needsApproval
            );
            setAddingAdmin(false);
        } catch (e) {
            setAddingAdmin(false);
            if (e instanceof FormException) {
                setErrors(e.errors);
            }
        }
    };

    const removeAdmin = async user_id => {
        try {
            const headers = config.bearerHeaders(authToken);
            await config.postApi(
                REMOVE_ADMIN_ENDPOINT(user_id),
                headers,
                JSON.stringify({})
            );
            await getRelationsAction();
            await getAdminsAction();
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            {/* Header Sec Start Here */}
            <Header />
            {/* Header Sec End Here */}

            {/* Inner Banner Sec Start Here */}
            <InnerBanner PageName="Add Admin" />
            {/* Inner Banner Sec End Here */}

            {/* Add Admin Sec Start Here */}
            <section className="add_admin">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="add-admin">
                                <div className="title">
                                    <h3>Add Admin</h3>
                                </div>
                                <form action="">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <MyInput
                                                label="Admin First Name"
                                                value={firstName}
                                                onChange={e =>
                                                    setFirstName(e.target.value)
                                                }
                                                errors={errors.first_name}
                                                inputId="first_name"
                                                showLabel
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <MyInput
                                                label="Admin Last Name"
                                                value={lastName}
                                                onChange={e =>
                                                    setLastName(e.target.value)
                                                }
                                                errors={errors.last_name}
                                                inputId="last_name"
                                                showLabel
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <MyInput
                                                label="Admin Email"
                                                value={email}
                                                onChange={e =>
                                                    setEmail(e.target.value)
                                                }
                                                errors={errors.email}
                                                inputId="email"
                                                showLabel
                                                type="email"
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <MySelect
                                                label="Select from list"
                                                options={members
                                                    .filter(
                                                        member =>
                                                            !member.is_admin
                                                    )
                                                    .map(member => ({
                                                        value: member.id,
                                                        label: member.full_name
                                                    }))}
                                                value={memberId}
                                                onChange={e =>
                                                    setMemberId(e.target.value)
                                                }
                                                errors={errors.member_id}
                                                placeholder="Select"
                                                inputId="member_id"
                                                showLabel
                                            />
                                        </div>
                                        <Col md={12}>
                                            <MyCheckbox
                                                inputId="needs_approval"
                                                value={needsApproval}
                                                onChange={e =>
                                                    setNeedsApproval(
                                                        e.target.checked
                                                    )
                                                }
                                                label="Needs Approval"
                                                showLabel
                                                errors={errors.needs_approval}
                                            />
                                        </Col>
                                        <div className="col-lg-12">
                                            <div className="button-group">
                                                <button
                                                    className="btn"
                                                    type="button"
                                                    disabled={addingAdmin}
                                                    onClick={addAdmin}
                                                >
                                                    {addingAdmin ? (
                                                        <Spinner />
                                                    ) : (
                                                        "Add Admin"
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="all-admins">
                                <div className="title">
                                    <h3>All Admins</h3>
                                </div>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Make date</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {admins.map(admin => (
                                                <tr
                                                    key={`admin_${admin.user.id.toString()}`}
                                                >
                                                    <td>
                                                        {admin.user.full_name}
                                                    </td>
                                                    <td>{admin.user.email}</td>
                                                    <td>
                                                        {moment(
                                                            admin.user
                                                                .created_at
                                                        ).format("D/MMM/YYYY")}
                                                    </td>
                                                    <td>
                                                        <button
                                                            className="remove"
                                                            type="button"
                                                            onClick={() =>
                                                                removeAdmin(
                                                                    admin.user
                                                                        .id
                                                                )
                                                            }
                                                        >
                                                            Remove
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="bottom-bar">
                                    <div className="total">
                                        <h4>
                                            <span>Total Preserved</span>{" "}
                                            <span>
                                                {currentPackage?.storage?.toFixed(
                                                    2
                                                )}
                                                GB{" "}
                                                <img
                                                    src={StorageFullIcon}
                                                    alt=""
                                                    className="img-fluid icon"
                                                />
                                            </span>
                                        </h4>
                                        <h4>
                                            <span>Preserved</span>{" "}
                                            <span>
                                                {currentPackage?.used_storage?.toFixed(
                                                    2
                                                )}
                                                GB{" "}
                                                <img
                                                    src={StorageUsedIcon}
                                                    alt=""
                                                    className="img-fluid icon"
                                                />
                                            </span>
                                        </h4>
                                        <h4>
                                            <span>Remaining</span>{" "}
                                            <span>
                                                {currentPackage?.remaining_storage?.toFixed(
                                                    2
                                                )}
                                                GB{" "}
                                                <img
                                                    src={StorageRemainIcon}
                                                    alt=""
                                                    className="img-fluid icon"
                                                />
                                            </span>
                                        </h4>
                                    </div>
                                    <div className="button-group">
                                        <Link className="upgrade" to="/pricing">
                                            Upgrade
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Add Admin Sec End Here */}

            {/* Footer Sec Start Here */}
            <Footer />
            {/* Footer Sec End Here */}
        </>
    );
};

const mapStateToProps = state => ({
    admins: state.admins,
    members: state.relations,
    authToken: state.auth.token
});

const mapDispatchToProps = dispatch => ({
    addAdminAction: async (
        memberId,
        firstName,
        lastName,
        email,
        needsApproval = true
    ) =>
        dispatch(
            await AddAdminAction(
                memberId,
                firstName,
                lastName,
                email,
                needsApproval
            )
        ),
    getAdminsAction: async () => dispatch(await GetAdminsAction()),
    getRelationsAction: async () => dispatch(await GetRelationsAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(Owner);
