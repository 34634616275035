import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { HOBBY_ENDPOINT, PETS_ENDPOINT } from "../../apis";
import config from "../../config";
import { connect } from "react-redux";

const AddPetsModal = ({ toggle, isOpen, getPets, editPets, authToken }) => {
    const [pets, setPets] = useState("");
    const [owner, setOwner] = useState("");
    const [color, setColor] = useState("#000000");
    const [mass, setMass] = useState("");

    const handleCreate = async event => {
        event.preventDefault();
        if (editPets) {
            try {
                // setSpinner(true);
                const headers = config.bearerHeaders(authToken);
                const data = JSON.stringify({ name: pets, owner, color, mass });
                const res = await config.putApi(
                    `${PETS_ENDPOINT}/${editPets.id}`,
                    headers,
                    data
                );
                if (res) {
                    setPets("");
                    setOwner("");
                    setColor("");
                    setMass("");
                    getPets();
                    toggle();
                }

                // setSpinner(false);
            } catch (e) {
                // setSpinner(false);
                console.log(e);
            }
        } else {
            try {
                // setSpinner(true);
                const headers = config.bearerHeaders(authToken);
                const data = JSON.stringify({
                    name: pets,
                    owner,
                    color,
                    mass
                });
                const res = await config.postApi(PETS_ENDPOINT, headers, data);
                if (res) {
                    setPets("");
                    setOwner("");
                    setColor("");
                    setMass("");
                    getPets();
                    toggle();
                }
                // setSpinner(false);
            } catch (e) {
                // setSpinner(false);
                console.log(e);
            }
        }
    };

    useEffect(() => {
        if (editPets) {
            setPets(editPets?.name);
            setOwner(editPets?.owner);
            setColor(editPets?.color);
            setMass(editPets.mass);
        }
    }, [editPets]);

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle} className="h2">
                {editPets ? "Edit Pets" : "Add Pets"}
            </ModalHeader>
            <ModalBody>
                <p className="small mb-1">Pet Name</p>
                <input
                    type="text"
                    className="form-control form-control-sm mb-3"
                    placeholder="Pet Name Here..."
                    value={pets}
                    onChange={e => setPets(e.target.value)}
                />
                <p className="small mb-1">Owner Name</p>
                <input
                    type="text"
                    className="form-control form-control-sm mb-3"
                    placeholder="Owner Name Here..."
                    value={owner}
                    onChange={e => setOwner(e.target.value)}
                />
                <p className="small mb-1">Mass</p>
                <input
                    type="number"
                    className="form-control form-control-sm mb-3"
                    placeholder="Mass"
                    value={mass}
                    onChange={e => setMass(e.target.value)}
                />
                <p className="small">Choose Color</p>
                <input
                    type="color"
                    value={color}
                    onChange={e => setColor(e.target.value)}
                />
            </ModalBody>
            <ModalFooter>
                <Button className="btn-sm" onClick={toggle}>
                    Cancel
                </Button>
                <Button className="btn-success btn-sm" onClick={handleCreate}>
                    {editPets ? "Update" : "Create"}
                </Button>
            </ModalFooter>
        </Modal>
    );
};
const mapStateToProps = state => ({
    authToken: state.auth.token
});

export default connect(mapStateToProps)(AddPetsModal);
