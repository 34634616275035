// Home images
import Logo from "../assets/img/logo.png";
import Dots from "../assets/img/dots.webp";
import Abstract from "../assets/img/abstract.webp";
import FamilyTree from "../assets/img/family-tree.webp";
import NewMemeber from "../assets/img/new-member.webp";
import TreePic from "../assets/img/tree-pic.webp";
import Icon1 from "../assets/img/icon1.png";
import Icon2 from "../assets/img/icon2.png";
import Icon3 from "../assets/img/icon3.png";
import Storage from "../assets/img/storage.webp";
import PlaystoreBtn from "../assets/img/playstore.webp";
import ApplestoreBtn from "../assets/img/applestore.webp";
import MotherPic from "../assets/img/mother.webp";
import FamilyCollage from "../assets/img/family-collage.jpg";

// About images
import AboutPic from "../assets/img/about.webp";

// How we do it images
import LongTermPic from "../assets/img/long-term.webp";
import PercentagePic from "../assets/img/percentage.webp";
import Planning from "../assets/img/planing.webp";

// Contact Image
import ContactImg from "../assets/img/contact-img.jpg";

// Profile Images
import ProfilePic from "../assets/img/profile.png";
import RecentPic1 from "../assets/img/recent1.png";
import RecentPic2 from "../assets/img/recent2.png";
import PhotosIcon from "../assets/img/photos.png";
import LiveIcon from "../assets/img/live.png";
import CommentPic1 from "../assets/img/comment1.png";
import CommentPic2 from "../assets/img/comment2.png";
import CommentPic3 from "../assets/img/comment3.png";
import CommentPic4 from "../assets/img/comment4.png";
import profileImg from "../assets/img/user-profile.png";
import postimg1 from "../assets/img/postimg1.png";
import postimg2 from "../assets/img/postimg2.png";
import postimg3 from "../assets/img/postimg3.png";
import postimg4 from "../assets/img/postimg4.png";
import postimg5 from "../assets/img/postimg5.png";
import postimg6 from "../assets/img/postimg6.png";
import Follower1 from "../assets/img/follower1.png";
import Follower2 from "../assets/img/follower2.png";
import Follower3 from "../assets/img/follower3.png";
import Follower4 from "../assets/img/follower4.png";
import Follower5 from "../assets/img/follower5.png";
import Follower6 from "../assets/img/follower6.png";
import Follower7 from "../assets/img/follower7.png";
import Follower8 from "../assets/img/follower8.png";
import Follower9 from "../assets/img/follower9.png";
import Follower10 from "../assets/img/follower10.png";
import Follower11 from "../assets/img/follower11.png";
import Follower12 from "../assets/img/follower12.png";
import Follower13 from "../assets/img/follower13.png";
import Follower14 from "../assets/img/follower14.png";

// Dashboard Images
import NoPreview from "../assets/img/no-preview.jpg";
import StorageFullIcon from "../assets/img/storage-full.webp";
import StorageRemainIcon from "../assets/img/remaining-storage.webp";
import StorageUsedIcon from "../assets/img/used-storage.webp";

// Chat Images
import msg1 from "../assets/img/msg1.png";
import msg2 from "../assets/img/msg2.png";
import msg3 from "../assets/img/msg3.png";
import msg4 from "../assets/img/msg4.png";
import msg5 from "../assets/img/msg5.png";
import NoMessage from "../assets/img/no_messages.webp"

// Payment Page Images
import VisaCard from "../assets/img/visa.png"

const TIMELINE_STATUS_PENDING = "pending";
const TIMELINE_STATUS_APPROVED = "approved";
const TIMELINE_STATUS_REJECTED = "rejected";

export {
    Logo,
    Dots,
    Abstract,
    FamilyTree,
    NewMemeber,
    TreePic,
    Icon1,
    Icon2,
    Icon3,
    Storage,
    PlaystoreBtn,
    ApplestoreBtn,
    MotherPic,
    AboutPic,
    LongTermPic,
    PercentagePic,
    Planning,
    ProfilePic,
    RecentPic1,
    RecentPic2,
    PhotosIcon,
    LiveIcon,
    CommentPic1,
    CommentPic2,
    CommentPic3,
    CommentPic4,
    NoPreview,
    StorageFullIcon,
    StorageRemainIcon,
    StorageUsedIcon,
    ContactImg,
    FamilyCollage,
    profileImg,
    postimg1,
    postimg2,
    postimg3,
    postimg4,
    postimg5,
    postimg6,
    Follower1,
    Follower2,
    Follower3,
    Follower4,
    Follower5,
    Follower6,
    Follower7,
    Follower8,
    Follower9,
    Follower10,
    Follower11,
    Follower12,
    Follower13,
    Follower14,
    msg1,
    msg2,
    msg3,
    msg4,
    msg5,
    TIMELINE_STATUS_APPROVED,
    TIMELINE_STATUS_PENDING,
    TIMELINE_STATUS_REJECTED,
    VisaCard,
    NoMessage,
};
